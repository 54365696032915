import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { ReactComponent as UploadIcon } from "../../assets/images/common/cloud-upload-alt.svg";
import { ReactComponent as PlusIcon } from "../../assets/images/common/plus-circle-line.svg";
import imageToBase64 from 'image-to-base64/browser';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useLocation, useNavigate } from 'react-router-dom';
import { RiDeleteBin6Line } from "react-icons/ri";
import BoardMembers from './BoardMembers';
import About from './About';
import Gallery from './Gallery';
import { CLUB_PROFILE } from '../../constants/pathname';
import { cityListDropdown } from '../../api/actions/city';
import { countryListDropdown } from '../../api/actions/country';
import { clubTypeListDropDown } from '../../api/actions/club';
import { createClubProfile, getClubProfile } from '../../api/actions/clubProfile';
import { GoogleMap, Marker, useLoadScript, InfoWindow } from '@react-google-maps/api';
import { checkTheme, stripHtml } from '../../utils/utils';
import ClubsStatusAlert from '../SweetAlert/ClubsStatusAlert';



const CreateClubProfile = () => {
    const navigate = useNavigate();
    const [publishedClub, setPublishedClub] = useState(false);
    const [isContentSwal, setContentSwal] = useState({ show: false, content: '' });
    // City and Country
    const [city, setCity] = useState([]);
    const [country, setCountry] = useState([]);
    const [showInfo, setShowInfo] = useState(false);
    const [isPublish, setIsPublish] = useState(false);
    const [clubTypeOptions, setClubTypeOptions] = useState([]);
    const [clubImages, setClubImages] = useState([]);
    const libraries = ["places"];
    const [loading, setLoading] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    let clubId = userInfo?.club_details?.[0]?.club_id;
    let countryId = userInfo?.club_details?.[0]?.country;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_KEY,
        libraries: libraries,
    });
    const { state } = useLocation();

    if (state?.country && state?.club) {
        countryId = state?.country;
        clubId = state?.club;
    }

    const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };
    const validationSchema = Yup.object({
        club_type: Yup.string().required('Required'),
        club_images_details: Yup.array()
            .min(1, 'At least one image is required')
            .required('Gallery images are required'),
        main_image: Yup.string().required('Main image is required'),
        about_club:Yup.string().required("Required"),
        club_rules: Yup.array()
            .of(
                Yup.object().shape({
                    club_rules: Yup.string().required('Club rule is required'),
                })
            )
            .min(1, 'At least one club rule is required')
            .required('Club rules are required'),
    });
    const formik = useFormik({
        initialValues: {
            images: [],
            thumbnailName: "",
            club_name: "",
            club_type: "",
            selected_clubtype: "",
            year_founded: "",
            total_members: "",
            club_website: "",
            club_logo: "",
            city: "",
            member_city: false,
            city_option: "",
            club_country: null,
            country: "",
            country_option: "",
            primary_address: "",
            secondary_address: "",
            about_club: "",
            club_rules: [
                {
                    club_rules: ""
                }
            ],
            honors_awards: "",
            golf_booking: false,
            golf_booking_option: { label: "No", value: false },
            contact_details: [
                {
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: ""
                }
            ],
            board_member_details: [],
            club_images_details: [],
            admin_email: "",
            reciprocal_club: false,
            gpcc: true,
            gpcc_option: { label: "Yes", value: true },
            latitude: 0,
            longitude: 0,
            PCW: !checkTheme(),
            PCN: checkTheme(),
            main_image: "",
            banner_image_video: "",
            apply_club_spotlight: true,
            solitaire_club: false,
            approve_club_spotlight: false,
            is_publish: false
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {

            if(values?.state){
                values.city += `, ${values.state}`;
            }
            if(typeof values?.country === "string"){
                values.city += `, ${values.country}`;
            } 

            const transformedValues = {
                ...values,
                club_type: values.club_type || null,
                club_website: values.club_website || null,
                total_members: values.total_members || null,
                year_founded: values.year_founded || null,
            };

            if (isPublish) {
                transformedValues.is_publish = true;
            }

            setLoading(true);
            createClubProfile({ data: transformedValues })((response) => {
                console.log(response);

                if (response.status_code === 200 || response.status_code === 201) {
                    if (isPublish) {
                        toast.success("Club Profile Published successfully!");
                    } else {
                        toast.success("Club profile created successfully!");
                    }
                    navigate(-1);
                } else {
                    toast.error(response?.response?.data?.errors?.non_field_errors[0]);
                }
                setLoading(false);
            });
        }

    });
    const handlePublish = () => {
        setIsPublish(true);
        setTimeout(() => {
            formik.handleSubmit();
        }, 800);
    }

    const handleChange = (e, name, selected_name) => {
        if (e) {
            formik.setValues({
                ...formik.values,
                [name]: e.value,
                [selected_name]: e,

            })
        } else {
            formik.setValues({
                ...formik.values,
                [name]: null,
                [selected_name]: [],
            })
        }
    }
    let typingTimer;

    const loadOptionsCLubType = (inputValue) => {
        return new Promise((resolve) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                if (inputValue.length >= 3) {
                    clubTypeListDropDown({ page: 1, limit: 10, search_param: inputValue })((response) => {
                        if (response?.status_code === 200) {
                            const options = response?.data?.results || [];
                            setClubTypeOptions(options);
                            resolve(options);
                        } else {
                            resolve([]);
                        }
                    });
                } else {
                    resolve([]);
                }
            }, 300);
        });
    };


    const getClubtype = () => {
        clubTypeListDropDown({ page: 1, limit: 10 })(response => {
            if (response?.status_code == 200) {
                setClubTypeOptions(response?.data?.results)
            }
        })
    }
    useEffect(() => {
        getClubtype()

    }, [])


    useEffect(() => {
        if (clubId && countryId) {
            getClubProfile({ id: clubId, country: countryId })((response) => {
                if (response?.status) {
                    const clubImages = response.data?.club_images || [];
                    const latitude = response.data?.latitude || 0;
                    const longitude = response.data?.longitude || 0;

                    const contactDetails = response.data?.contact_details || [];
                    // const boardMembers = response.data?.board_members || [];

                    setMarkerPosition({ lat: latitude, lng: longitude });
                    const clubRules = response.data?.club_rules && response.data.club_rules.length > 0
                    ? response.data.club_rules
                    : [{ club_rules: "" }];
                    
                    let cityName = "";
                    if(typeof response?.data?.city_name === "string"){
                        let addArr = response?.data?.city_name?.split(",");
                        if(Array.isArray(addArr) && addArr?.length){
                            cityName = addArr[0];
                        }
                    }

                    formik.setValues({
                        ...formik.values,
                        id: response.data?.id,
                        club_name: response.data?.club_name,
                        main_image: response.data?.main_image,
                        club_type: response.data?.club_type_id,
                        year_founded: response.data?.year_founded,
                        main_image: response.data?.main_image,
                        first_name: response.data?.first_name,
                        last_name: response?.data?.last_name,
                        contact_number: response?.data?.contact,
                        total_members: response.data?.total_members,
                        club_website: response.data?.club_website,
                        country: response.data?.country_name,
                        state: response?.data?.state,
                        country_code:response?.data?.country_code,
                        member_city: response?.data?.member_city,
                        is_kyc: response?.data?.is_kyc,
                        zip_code: response?.data?.zip_code,
                        admin_email: response?.data?.admin_email,
                        city: cityName || response.data?.city_name,
                        primary_address: response.data?.primary_address,
                        secondary_address: response?.data?.secondary_address,
                        selected_clubtype: response.data?.club_type_id ? { label: response?.data?.club_type, value: response?.data?.club_type_id } : '',
                        superadmin_handled: response?.data?.superadmin_handled,
                        golf_booking:  response?.data?.golf_booking,
                        golf_booking_option: response?.data?.golf_booking ? { label: "Yes", value: true }: { label: "No", value: false },
                        gpcc: response?.data?.gpcc,
                        gpcc_option: response?.data?.gpcc
                            ? { label: "Yes", value: true }
                            : { label: "No", value: false },
                        club_images_details: clubImages,
                        latitude,
                        longitude,
                        contact_details: contactDetails.map(contact => ({
                            id: contact.id,
                            first_name: contact.first_name,
                            last_name: contact.last_name,
                            email: contact.email,
                            phone_number: contact.phone_number,
                            club_admin: contact?.club_admin
                        })),
                        club_rules: clubRules,
                        honors_awards: response.data?.honors_awards || "",
                        about_club: response?.data?.about_club || "",
                        is_publish: response?.data?.is_publish || "",
                        approve_club_spotlight: response?.data?.approve_club_spotlight || "",
                        solitaire_club: response?.data?.solitaire_club || "",
                        // board_member_details: boardMembers.map(member => ({
                        //     id: member.id,
                        //     first_name: member.first_name,
                        //     last_name: member.last_name,
                        //     designation: member.designation,
                        //     image: member.image
                        // })),
                    });

                    setPublishedClub(response?.data?.is_publish);
                }
            });
        }
    }, [clubId, countryId]);



    // Add a new contact
    const addContact = () => {
        formik.setFieldValue("contact_details", [
            ...formik?.values?.contact_details,
            { first_name: "", last_name: "", email: "", phone_number: "" }
        ]);
    };

    // Remove a contact
    const removeContact = (index) => {
        const updatedContacts = [...formik.values.contact_details];
        updatedContacts.splice(index, 1);
        formik.setFieldValue("contact_details", updatedContacts);
    };
    const handleImageChange = async (e) => {
        const files = Array.from(e?.target?.files);
        const maxSizeInBytes = 1 * 1024 * 1024;
        const validImageTypes = ['image/jpeg', 'image/png'];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!validImageTypes.includes(file?.type)) {
                toast.error("Only jpg/jpeg and png files are allowed!");
                e.target.value = null;
                return;
            }

            if (file?.size > maxSizeInBytes) {
                toast.error("File size exceeds 2 MB limit", 'error');
                e.target.value = null;
                return;
            }
        }

        const images = await Promise.all(
            files.map(async (file) => {
                try {
                    const response = await imageToBase64(URL.createObjectURL(file));
                    return `data:image/jpeg;base64,${response}`;
                } catch (error) {
                    return null;
                }
            })
        );

        // Setting file name
        let fileName = "";
        if (Array.isArray(files) && files[0]?.name) {
            fileName = files[0].name;
        }

        formik.setValues({
            ...formik.values,
            images: [...images], // Ensure images is an array
            thumbnailName: fileName,
            main_image: images[0] // Set the first image as the main_image
        });

        e.target.value = null;
    };

    const removeThumbNail = () => {
        formik.setValues({
            ...formik.values,
            images: [], // Ensure images is an array
            thumbnailName: ""
        });
    }




    console.log(formik)


    return (
        <>
            {/* Clubs Status content alert */}
            {isContentSwal?.content && <ClubsStatusAlert showClubsStatusAlert={isContentSwal} setShowClubsSwal={setContentSwal} error={isContentSwal?.content}/>}
            <div className='create-clubs-profile-section'>
                <div className='page-details'>
                    <div className='club-profile-section'>
                        <div className='panel-type-1'>
                            Club Profile
                        </div>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className='club-form mt-4'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        {/* Upload Thumbnail */}
                                        <div className='item upload'>
                                            <label> Upload Club Image <span className='required'>*</span></label>
                                            <div className='d-flex align-items-end mt-2'>
                                                <div>
                                                    {formik.values.main_image || (Array.isArray(formik.values?.images) && formik.values?.images?.length > 0) ? (
                                                        <div className={`uploaded-images ${formik.values.main_image || formik.values.images?.length > 0 ? 'active' : ''}`}>
                                                            <div className='d-flex pos-rel' style={{ width: "107px", height: "107px" }}>
                                                                <img
                                                                    src={formik.values.main_image || formik.values.images[0]}
                                                                    alt="Uploaded Thumbnail"
                                                                    className="uploaded-image"
                                                                />
                                                            </div>
                                                            {/* File name and delete button in one line */}
                                                            {(formik.values.thumbnailName || formik.values.main_image || formik.values.images?.length > 0) && (
                                                                <div className='d-flex align-items-center justify-content-between filename gap-2 mt-3'>
                                                                    <div className='name' title={formik.values.thumbnailName || "Uploaded Image"}>
                                                                        {/* {formik.values.thumbnailName } */}
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            formik.setValues({
                                                                                ...formik.values,
                                                                                main_image: "",
                                                                                images: [],
                                                                                thumbnailName: ""
                                                                            });
                                                                        }}
                                                                        style={{
                                                                            background: 'none',
                                                                            border: 'none',
                                                                            cursor: 'pointer',
                                                                            color: 'var(--red)',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            gap: '5px',
                                                                        }}
                                                                    >
                                                                        <RiDeleteBin6Line size={16} />
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <label className='cursor-pointer upload-icon' htmlFor='fileInput'>
                                                            <UploadIcon />
                                                            <div className='mt-3'>Upload</div>
                                                        </label>
                                                    )}
                                                    <div className='w-75 mt-2'>
                                                        {!formik.values.main_image && !(Array.isArray(formik.values?.images) && formik.values?.images?.length > 0) && (
                                                            <div className='m-0 info'>
                                                                Use JPEG or PNG. Best size 1280×720 pixels. Keep it under 1MB
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <input
                                                    id="fileInput"
                                                    className='d-none'
                                                    type="file"
                                                    onChange={handleImageChange}
                                                    accept="image/*"
                                                />
                                            </div>
                                            {formik.touched.main_image && formik.errors.main_image && (
                                                <div className="text-danger">{formik.errors.main_image}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-md-9'>
                                        {/* Basic Details */}
                                        <div className='row'>
                                            {/* Club Name */}

                                            <div className='col-md-6 col-xl-4 mb-3'>
                                                <Form.Group controlId="club_name">
                                                    <Form.Label>Club Name<span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Club Name"
                                                        name="club_name"
                                                        value={formik?.values?.club_name}
                                                        disabled
                                                        onChange={formik.handleChange}
                                                        isInvalid={formik?.touched?.club_name && formik?.errors?.club_name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik?.errors?.club_name}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className='col-md-6 col-xl-4 mb-3'>
                                                <Form.Group controlId="club_name">
                                                    <Form.Label>Email Id<span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Club Name"
                                                        name="admin_email"
                                                        disabled
                                                        value={formik?.values?.admin_email}
                                                        onChange={formik.handleChange}
                                                        isInvalid={formik?.touched?.admin_email && formik?.errors?.admin_email}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik?.errors?.admin_email}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>


                                            {/* Year Founded */}
                                            <div className="col-md-4 col-xl-4 mb-3">
                                                <Form.Group controlId="year_founded">
                                                    <Form.Label>Year Founded</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Year Founded"
                                                        name="year_founded"
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={formik.handleChange}
                                                        value={formik?.values?.year_founded}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.touched.year_founded && formik.errors.year_founded}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            {/* City */}
                                            <div className="col-md-3 col-xl-3 mb-4">
                                                <Form.Group controlId="city">
                                                    <Form.Label>City<span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="City"
                                                        name="city"
                                                        disabled
                                                        value={formik?.values?.city}
                                                        onChange={formik.handleChange}
                                                        isInvalid={formik?.touched?.city && formik?.errors?.city}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-3 col-xl-3 mb-4">
                                                <Form.Group controlId="country">
                                                    <Form.Label>State<span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="State"
                                                        name="state"
                                                        disabled
                                                        value={formik?.values?.state}
                                                        onChange={formik.handleChange}
                                                        isInvalid={formik?.touched?.state && formik?.errors?.state}
                                                    />
                                                </Form.Group>
                                            </div>
                                            {/* Country */}
                                            <div className="col-md-3 col-xl-3 mb-4">
                                                <Form.Group controlId="country">
                                                    <Form.Label>Country<span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Country"
                                                        name="country"
                                                        disabled
                                                        value={formik?.values?.country}
                                                        onChange={formik.handleChange}
                                                        isInvalid={formik?.touched?.country && formik?.errors?.country}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-3 col-xl-3 mb-4">
                                                <Form.Group controlId="country">
                                                    <Form.Label>Zip Code<span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Zip Code"
                                                        name="zip_code"
                                                        disabled
                                                        value={formik?.values?.zip_code}
                                                        onChange={formik.handleChange}
                                                        isInvalid={formik?.touched?.zip_code && formik?.errors?.zip_code}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 col-xl-12">
                                                <Form.Group controlId="handle">
                                                    <div className="d-flex align-items-center gap-3 mt-2">

                                                        <div className="custom-checkboxclub">
                                                            <input
                                                                type="checkbox"
                                                                id="cityodres"
                                                                name="member_city"
                                                                checked={formik.values.member_city}
                                                                onChange={(e) => formik.setFieldValue('member_city', e.target.checked)}
                                                            />
                                                            <label htmlFor="cityodres" style={{fontWeight:"bold"}}>Apply City of the club as default for all club members.</label>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            {/* <div className="col-md-4 col-xl-4 mb-3">
                                                <Form.Group controlId="first_name">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="first_name"
                                                        placeholder='First Name'
                                                        value={formik?.values?.first_name}
                                                        onChange={formik?.handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.touched.first_name && formik.errors.first_name}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-4 col-xl-4 mb-3">
                                                <Form.Group controlId="last_name">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="last_name"
                                                        placeholder='Last Name'
                                                        value={formik?.values?.last_name}
                                                        onChange={formik?.handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.touched.last_name && formik.errors.last_name}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-4 col-xl-4 mb-3">
                                                <Form.Group controlId="contact_number">
                                                    <Form.Label>Contact Number</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="contact_number"
                                                        placeholder='Contact Number'
                                                        value={formik?.values?.contact_number}
                                                        onChange={formik?.handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.touched.contact_number && formik.errors.contact_number}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <div className="row">
                                        {/* Type of Club */}
                                        <div className="col-md-4 col-xl-3 mb-4">
                                            <Form.Group controlId="club_type">
                                                <Form.Label>Type of Club<span className='required'>*</span></Form.Label>
                                                <AsyncSelect
                                                    loadOptions={loadOptionsCLubType}
                                                    defaultOptions={clubTypeOptions || []}
                                                    classNamePrefix="select"
                                                    isClearable={true}
                                                    placeholder={'Select Club Type'}
                                                    onChange={(e) => handleChange(e, 'club_type', 'selected_clubtype')}
                                                    value={formik.values?.selected_clubtype}
                                                    styles={{
                                                        control: (styles, { isFocused }) => ({
                                                            ...styles,
                                                            maxHeight: '31px',
                                                            border: formik.errors.cat && formik.touched.cat
                                                                ? '1px solid #dc3545'
                                                                : '1px solid var(--light-grayish)',
                                                            outline: 'none',
                                                            boxShadow: isFocused ? '0 0 0 1px var(--light-grayish)' : 'none',
                                                            color: 'var(--medium-gray)',
                                                            fontSize: '15px',
                                                            '&:hover': {
                                                                border: formik.errors.cat && formik.touched.club_type
                                                                    ? '1px solid #dc3545'
                                                                    : '1px solid var(--light-grayish)',
                                                            },
                                                        }),
                                                        valueContainer: (styles) => ({
                                                            ...styles,
                                                            alignItems: 'center',
                                                            padding: '3px 8px',
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: 'var(--medium-gray)',
                                                            fontSize: '15px',
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            border: '1px solid var(--light-grayish)',
                                                            boxShadow: 'none',
                                                        }),
                                                        placeholder: (styles) => ({
                                                            ...styles,
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            color: 'var(--medium-gray)',
                                                            fontSize: '15px',
                                                        }),
                                                    }}
                                                />
                                                {formik?.touched?.club_type && formik?.errors?.club_type ?
                                                    <div className='error-msg'>{formik?.errors?.club_type}</div>
                                                    : <></>}
                                            </Form.Group>
                                        </div>
                                        {/* Golf Booking */}
                                        <div className="col-md-4 col-xl-3 mb-4">
                                            <Form.Group controlId="golf_booking">
                                                <Form.Label>Golf Booking</Form.Label>
                                                <Select
                                                    placeholder="Select"
                                                    styles={customStyles}
                                                    options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                                    value={formik?.values?.golf_booking_option}
                                                    name="golf_booking"
                                                    onChange={(option) => {
                                                        formik?.setValues({
                                                            ...formik?.values,
                                                            golf_booking: option?.value,
                                                            golf_booking_option: option
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        {/* Global Private Club Participation (Yes/No) */}
                                        {!checkTheme() && (
                                            <div className="col-md-4 col-xl-3 mb-4">
                                                <Form.Group controlId="gpcc">
                                                    <Form.Label>Global Private Club Participation</Form.Label>
                                                    <Select
                                                        placeholder="Select"
                                                        styles={customStyles}
                                                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                                        name='gpcc'
                                                        value={formik?.values?.gpcc_option}
                                                        onChange={(option) => {
                                                            if (!checkTheme() && !userInfo?.is_superuser && (formik?.values?.solitaire_club || formik?.values?.approve_club_spotlight)) {
                                                                setContentSwal({ show: true, content: `Please contact Support to moderate your participation in Private Club World's Global Private Club network` });
                                                                return;
                                                            }
                                                            formik?.setValues({
                                                                ...formik?.values,
                                                                gpcc: option?.value,
                                                                gpcc_option: option
                                                            });
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        )}

                                        {/* Number of Members */}
                                        <div className="col-md-4 col-xl-3 mb-4">
                                            <Form.Group controlId="total_members">
                                                <Form.Label>Number of Members</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Number of Members"
                                                    name="total_members"
                                                    onWheel={(e) => e.target.blur()}
                                                    value={formik?.values?.total_members}
                                                    onChange={formik?.handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik.touched.total_members && formik.errors.total_members}</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        {/* Club Website */}
                                        <div className="col-md-4 col-xl-4 mb-3">
                                            <Form.Group controlId="club_website">
                                                <Form.Label>Club Website</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="club_website"
                                                    placeholder='Club Website'
                                                    value={formik?.values?.club_website}
                                                    onChange={formik?.handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik.touched.club_website && formik.errors.club_website}</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        {/* Address 1* */}
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <Form.Group controlId="primary_address">
                                                <Form.Label>Address 1<span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Address 1"
                                                    name="primary_address"
                                                    rows={4}
                                                    value={formik?.values?.primary_address}
                                                    onChange={formik?.handleChange}
                                                />
                                                {formik?.touched?.primary_address && formik?.errors?.primary_address ?
                                                    <div className='error-msg'>{formik?.errors?.primary_address}</div>
                                                    : <></>}
                                            </Form.Group>
                                        </div>
                                        {/* Address 2 */}
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <Form.Group controlId="secondary_address">
                                                <Form.Label>Address 2</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Address 2"
                                                    name="secondary_address"
                                                    value={formik.values?.secondary_address}
                                                    rows={4}
                                                    onChange={(e) => {
                                                        formik?.setValues({
                                                            ...formik?.values,
                                                            secondary_address: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        {/* Contact */}
                                        <div className="col-md-12 col-xl-12 mb-4">
                                            <Form.Group controlId="contact">
                                                <Form.Label>Contact <span className='required'>*</span></Form.Label>
                                                <div>
                                                    {formik.values.contact_details?.map((contact, index) => (
                                                        <div className='row align-items-start item mb-4' key={index}>
                                                            {/* First Name */}
                                                            <div className='col-md-3'>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="First Name"
                                                                    name={`contact_details[${index}].first_name`}
                                                                    value={contact.first_name}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    disabled={contact?.club_admin}
                                                                />
                                                                {formik.touched.contact_details?.[index]?.first_name &&
                                                                    formik.errors.contact_details?.[index]?.first_name && (
                                                                        <div className="error-msg">{formik.errors.contact_details[index].first_name}</div>
                                                                    )}
                                                            </div>

                                                            {/* Last Name */}
                                                            <div className='col-md-2'>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Last Name"
                                                                    name={`contact_details[${index}].last_name`}
                                                                    value={contact.last_name}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    disabled={contact?.club_admin}
                                                                />
                                                            </div>

                                                            {/* Email */}
                                                            <div className='col-md-3'>
                                                                <Form.Control
                                                                    type="email"
                                                                    placeholder="Email*"
                                                                    name={`contact_details[${index}].email`}
                                                                    value={contact.email}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    disabled={contact?.club_admin}
                                                                />
                                                                {formik.touched.contact_details?.[index]?.email &&
                                                                    formik.errors.contact_details?.[index]?.email && (
                                                                        <div className="error-msg">{formik.errors.contact_details[index].email}</div>
                                                                    )}
                                                            </div>

                                                            {/* Phone Number */}
                                                            <div className='col-md-3'>
                                                                <Form.Control
                                                                    type="tel"
                                                                    placeholder="Phone Number*"
                                                                    name={`contact_details[${index}].phone_number`}
                                                                    value={contact.phone_number}
                                                                    onChange={(e) => {
                                                                        const { value } = e.target;
                                                                        if (/^[\d\s-+()]*$/.test(value) || value === "") {
                                                                            formik.handleChange(e);
                                                                        }
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    disabled={contact?.club_admin}
                                                                />
                                                                {formik.touched.contact_details?.[index]?.phone_number &&
                                                                    formik.errors.contact_details?.[index]?.phone_number && (
                                                                        <div className="error-msg">{formik.errors.contact_details[index].phone_number}</div>
                                                                    )}
                                                            </div>

                                                            {/* Delete Button */}
                                                            {index > 0 && (
                                                                <div className="col-md-1">
                                                                    <div className='d-flex align-items-center'
                                                                        style={{ background: "var(--light-gray)", width: "fit-content", padding: "10px", borderRadius: "6px" }}>
                                                                        <RiDeleteBin6Line
                                                                            className="cursor-pointer error-msg"
                                                                            onClick={() => removeContact(index)}
                                                                            title="Delete Contact"
                                                                            style={{ background: "var(--white)" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Add Contact */}
                                                <div
                                                    className={`${formik.values.contact_details?.length > 3 ? 'pe-none opacity-50' : ''} mt-4 add-contact d-flex align-items-center gap-2 cursor-pointer disable`}
                                                    onClick={addContact}
                                                    style={{ width: "fit-content" }}
                                                >
                                                    <PlusIcon />
                                                    <div>Add Contact</div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* Board Members */}
                                {/* <BoardMembers formik={formik} /> */}
                                {/* About */}
                                <About formik={formik} />

                                <div className='mt-5 panel-type-1'>
                                    About Club
                                </div>
                                <div className="col-md-12 col-xl-12 mt-4">
                                    <Form.Group controlId="primary_address">


                                        <Form.Control
                                            as="textarea"
                                            placeholder="About Club"
                                            name="about_club"
                                            rows={8}
                                            value={stripHtml(formik.values?.about_club)}
                                            onChange={formik?.handleChange}
                                        />
                                        {formik?.touched?.about_club && formik?.errors?.about_club ?
                                            <div className='error-msg'>{formik?.errors?.about_club}</div>
                                            : <></>}
                                    </Form.Group>
                                </div>

                                <div className='mt-5 panel-type-1'>
                                    Location <span className="required">*</span>
                                </div>
                                {isLoaded ? (
                                    <div className="location-map mt-4">
                                        <div className="map-box">
                                            {isLoaded ? (
                                                <GoogleMap
                                                    mapContainerStyle={{ width: "100%", height: "400px" }}
                                                    center={markerPosition}
                                                    zoom={15}
                                                >

                                                    <Marker
                                                        position={markerPosition}
                                                        onClick={() => setShowInfo(true)}
                                                    />



                                                    <InfoWindow
                                                        position={markerPosition}
                                                        onCloseClick={() => setShowInfo(false)}
                                                    >
                                                        <div style={{
                                                            width: "200px",
                                                            textAlign: "center",
                                                            fontWeight: "bold",
                                                            fontSize: "14px",
                                                            background: "#fff",

                                                            borderRadius: "8px",
                                                            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)"
                                                        }}>
                                                            <div>{formik.values.club_name || "Club Name"}</div>
                                                            <div style={{ fontSize: "12px", fontWeight: "normal", marginTop: "5px" }}>
                                                                {formik.values.primary_address || "Address not available"}
                                                            </div>
                                                        </div>
                                                    </InfoWindow>

                                                </GoogleMap>
                                            ) : (
                                                <div>Loading map...</div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div>Loading map...</div>
                                )}
                                {/* Gallery */}
                                <Gallery formik={formik} clubImages={clubImages} />
                                <div className='d-flex align-items-center justify-content-end gap-3 submit-box'>
                                    <button className='btn-type-2 cursor-pointer' type='button' onClick={() => navigate(-1)}>Cancel</button>

                                    {!publishedClub &&
                                        <button
                                            className='btn-type-1 publish-btn'
                                            type='button'
                                            onClick={() => handlePublish()}
                                            disabled={loading}
                                        >
                                            Publish
                                        </button>}



                                    <button className='btn-type-1' type="submit" disabled={loading}>Update</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateClubProfile