export const checkThemePcw = () => {
    return true;
    if (window.location.origin == '' || window.location.origin == '') {
        return true
    }
    else {
        return true
    }
}
export const checkTheme = () => {
    if (window.location.origin == 'https://club.platinumclubnet.com' || window.location.origin == 'https://pcn-cms.aventusinformatics.com') {
        return true
    }
    else {
        return false        
    }
}
export const PCW_URL = 'https://club.privateclubworld.com'
export const PCN_URL = 'https://club.platinumclubnet.com'

export const switchApp = (token, refresh) => {
    const baseUrl = checkTheme()
        ? "https://member.platinumclubnet.com/login"
        : "https://member.privateclubworld.com/login"
  
    window.location.href = `${baseUrl}?token=${token}&refresh=${refresh}`;
  };
  


  export const stripPTag = (str= "") => {
    return str.replaceAll("<p>","").replaceAll("</p>", "")
  }
  export const stripH1Tag = (str= "") => {
    return str.replaceAll("<h1>","").replaceAll("</h1>", "")
  }


 export  const stripNBSP = (str = "") => {
    return str.replaceAll("&nbsp;","")

  }

  export  const stripStrong = (str = "") => {
    return str.replaceAll("<strong>","").replaceAll("</strong>", "")

  }

  export const stripHtml = (str = "") => {
    return stripH1Tag(stripPTag(stripNBSP(stripStrong(str))))
  }